import * as React from 'react';
import styles from '../agtech.module.css';
import Layout from '../components/layout';
import Bullets from 'components/bullets';
import Tile from 'components/tile';
import GetConsultation from 'components/free-consultation';
import Blog from 'components/blog/widget';
import Booking from 'components/feedback';
import { Backbone } from 'components/carousel';
import identificators from 'components/googleAnalyticsIdentificators';
import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes').Routes;

const LIST = [
  {
    title: 'Event Planning and Scheduling',
    text: 'Automate and simplify a planning strategy with user-friendly dashboards to determine milestones and cover the whole lifecycle of your events.',
  },
  {
    title: 'Communication Tooling',
    text: 'Strengthen collaboration with suppliers and enhance your intra-team communication thanks to built-in chats and follow-up messages.',
  },
  {
    title: 'Tickets and Sales Management ',
    text: 'Leverage a ticketing platform features to effectively organize and sell tickets, promote your events and convert leads to bookings.',
  },
  {
    title: 'Team and Task Management',
    text: 'Assign tasks for multiple events at once, monitor your teams’ activities and manage their workflow based on decent accountability.',
  },
  {
    title: 'Inventory Management',
    text: 'Come up with an automated and transparent inventory tracking of your equipment throughout the entire supply chain to be on top of things.',
  },
  {
    title: 'Event Budgeting and Cost Tracking',
    text: 'Map out your budget thanks to AI-enabled forecasts and keep track of your expenses in a single easy-to-use application.',
  },
];

const SERVICES = [
  {
    name: 'agritech-startup',
    title: 'Event Service Startups',
    text: 'Have a revolutionary idea? We are eager to create a project from scratch or perfect your existing MVP.',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'agritech-b2b-company',
    title: 'Event Production Companies',
    text: 'We gather a dedicated team to take care of every development step while saving your budget.',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'farm-owner',
    title: 'Marketing Deparments in Enterprises',
    text: 'We embrace the latest tech trends to reshape your legacy infrastructure with digitally-enabled solutions.',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function EventsManagement({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiIndustry', 'Event management');

  return (
    <Layout
      title="Events Management Software Development"
      subtitle="We build smart cloud-based solutions to enable event providers seamlessly plan and manage their projects and stay on top of their schedules."
      linkText="Estimate your project"
      metaData={{ main: meta.eventsManagment, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.ESTIMATE_YOUR_PROJECT_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className="title">We can help you with</h2>
          <p className="subtitle">Find out how to boost your workflow with our all-in-one tools</p>
          <Bullets list={LIST} className={styles.bullets} />
        </div>
      </section>
      {/* <SwiperSlider> */}
      <Backbone />
      {/* </SwiperSlider> */}
      <section className="section">
        <div className="inner">
          <h2 className="title">Services we provide</h2>
          <p className="subtitle">Leave the development process to us</p>
          <Tile list={SERVICES} className={styles.imageBullets} />
        </div>
      </section>
      <GetConsultation
        title="Deliver the very best possible experience with us"
        text="Empowering Events Management businesses with multi-functioning solutions"
        linkText="schedule a call"
        linkId={identificators.SCHEDULE_A_CALL_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default EventsManagement;
